import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4c564c94 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a3de6628 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _7fb76740 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _341c24a7 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _b9070666 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _317a3e62 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _726659ac = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _a7bae322 = () => interopDefault(import('../pages/price-request.vue' /* webpackChunkName: "pages/price-request" */))
const _2eabdcd0 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5bdf6622 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _716415f7 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1646e0d9 = () => interopDefault(import('../pages/products/favourites.vue' /* webpackChunkName: "pages/products/favourites" */))
const _a9eea552 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _96a68a8c = () => interopDefault(import('../pages/profile/price-requests.vue' /* webpackChunkName: "pages/profile/price-requests" */))
const _1461c5bd = () => interopDefault(import('../pages/profile/reports.vue' /* webpackChunkName: "pages/profile/reports" */))
const _760100e1 = () => interopDefault(import('../pages/profile/search-history.vue' /* webpackChunkName: "pages/profile/search-history" */))
const _2a4bbc86 = () => interopDefault(import('../pages/profile/user.vue' /* webpackChunkName: "pages/profile/user" */))
const _5d910e82 = () => interopDefault(import('../pages/search/oem/index.vue' /* webpackChunkName: "pages/search/oem/index" */))
const _157a9ee7 = () => interopDefault(import('../pages/search/oem/_id.vue' /* webpackChunkName: "pages/search/oem/_id" */))
const _4c2db662 = () => interopDefault(import('../pages/chat/_id.vue' /* webpackChunkName: "pages/chat/_id" */))
const _4c2f1d6c = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _3a251f04 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _97ff1a5a = () => interopDefault(import('../pages/catalog/_slug/obzor.vue' /* webpackChunkName: "pages/catalog/_slug/obzor" */))
const _1376a992 = () => interopDefault(import('../pages/password-reset/_uid/_token.vue' /* webpackChunkName: "pages/password-reset/_uid/_token" */))
const _cd28f08a = () => interopDefault(import('../pages/catalog/_slug/_slug/_filter.vue' /* webpackChunkName: "pages/catalog/_slug/_slug/_filter" */))
const _299b8b4d = () => interopDefault(import('../pages/catalog/_.vue' /* webpackChunkName: "pages/catalog/_" */))
const _47c948dd = () => interopDefault(import('../pages/profile/_.vue' /* webpackChunkName: "pages/profile/_" */))
const _b1deae7a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/",
    component: _4c564c94,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/ru/",
    component: _4c564c94,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/en/cart/",
    component: _a3de6628,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en"
  }, {
    path: "/en/catalog/",
    component: _7fb76740,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___en"
  }, {
    path: "/en/chat/",
    component: _341c24a7,
    pathToRegexpOptions: {"strict":true},
    name: "chat___en"
  }, {
    path: "/en/login/",
    component: _b9070666,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/en/news/",
    component: _317a3e62,
    pathToRegexpOptions: {"strict":true},
    name: "news___en"
  }, {
    path: "/en/password-reset/",
    component: _726659ac,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___en"
  }, {
    path: "/en/price-request/",
    component: _a7bae322,
    pathToRegexpOptions: {"strict":true},
    name: "price-request___en"
  }, {
    path: "/en/profile/",
    component: _2eabdcd0,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/en/register/",
    component: _5bdf6622,
    pathToRegexpOptions: {"strict":true},
    name: "register___en"
  }, {
    path: "/en/search/",
    component: _716415f7,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/ru/cart/",
    component: _a3de6628,
    pathToRegexpOptions: {"strict":true},
    name: "cart___ru"
  }, {
    path: "/ru/catalog/",
    component: _7fb76740,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___ru"
  }, {
    path: "/ru/chat/",
    component: _341c24a7,
    pathToRegexpOptions: {"strict":true},
    name: "chat___ru"
  }, {
    path: "/ru/login/",
    component: _b9070666,
    pathToRegexpOptions: {"strict":true},
    name: "login___ru"
  }, {
    path: "/ru/news/",
    component: _317a3e62,
    pathToRegexpOptions: {"strict":true},
    name: "news___ru"
  }, {
    path: "/ru/password-reset/",
    component: _726659ac,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___ru"
  }, {
    path: "/ru/price-request/",
    component: _a7bae322,
    pathToRegexpOptions: {"strict":true},
    name: "price-request___ru"
  }, {
    path: "/ru/profile/",
    component: _2eabdcd0,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/register/",
    component: _5bdf6622,
    pathToRegexpOptions: {"strict":true},
    name: "register___ru"
  }, {
    path: "/ru/search/",
    component: _716415f7,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/en/products/favourites/",
    component: _1646e0d9,
    pathToRegexpOptions: {"strict":true},
    name: "products-favourites___en"
  }, {
    path: "/en/profile/orders/",
    component: _a9eea552,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___en"
  }, {
    path: "/en/profile/price-requests/",
    component: _96a68a8c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-price-requests___en"
  }, {
    path: "/en/profile/reports/",
    component: _1461c5bd,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reports___en"
  }, {
    path: "/en/profile/search-history/",
    component: _760100e1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-search-history___en"
  }, {
    path: "/en/profile/user/",
    component: _2a4bbc86,
    pathToRegexpOptions: {"strict":true},
    name: "profile-user___en"
  }, {
    path: "/en/search/oem/",
    component: _5d910e82,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem___en"
  }, {
    path: "/ru/products/favourites/",
    component: _1646e0d9,
    pathToRegexpOptions: {"strict":true},
    name: "products-favourites___ru"
  }, {
    path: "/ru/profile/orders/",
    component: _a9eea552,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___ru"
  }, {
    path: "/ru/profile/price-requests/",
    component: _96a68a8c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-price-requests___ru"
  }, {
    path: "/ru/profile/reports/",
    component: _1461c5bd,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reports___ru"
  }, {
    path: "/ru/profile/search-history/",
    component: _760100e1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-search-history___ru"
  }, {
    path: "/ru/profile/user/",
    component: _2a4bbc86,
    pathToRegexpOptions: {"strict":true},
    name: "profile-user___ru"
  }, {
    path: "/ru/search/oem/",
    component: _5d910e82,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem___ru"
  }, {
    path: "/en/search/oem/:id/",
    component: _157a9ee7,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem-id___en"
  }, {
    path: "/ru/search/oem/:id/",
    component: _157a9ee7,
    pathToRegexpOptions: {"strict":true},
    name: "search-oem-id___ru"
  }, {
    path: "/en/chat/:id/",
    component: _4c2db662,
    pathToRegexpOptions: {"strict":true},
    name: "chat-id___en"
  }, {
    path: "/en/news/:id/",
    component: _4c2f1d6c,
    pathToRegexpOptions: {"strict":true},
    name: "news-id___en"
  }, {
    path: "/en/product/:id?/",
    component: _3a251f04,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___en"
  }, {
    path: "/ru/chat/:id/",
    component: _4c2db662,
    pathToRegexpOptions: {"strict":true},
    name: "chat-id___ru"
  }, {
    path: "/ru/news/:id/",
    component: _4c2f1d6c,
    pathToRegexpOptions: {"strict":true},
    name: "news-id___ru"
  }, {
    path: "/ru/product/:id?/",
    component: _3a251f04,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___ru"
  }, {
    path: "/en/catalog/:slug/obzor/",
    component: _97ff1a5a,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-obzor___en"
  }, {
    path: "/ru/catalog/:slug/obzor/",
    component: _97ff1a5a,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-obzor___ru"
  }, {
    path: "/en/password-reset/:uid?/:token/",
    component: _1376a992,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset-uid-token___en"
  }, {
    path: "/ru/password-reset/:uid?/:token/",
    component: _1376a992,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset-uid-token___ru"
  }, {
    path: "/en/catalog/:slug/:slug?/:filter?/",
    component: _cd28f08a,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-slug-filter___en"
  }, {
    path: "/ru/catalog/:slug/:slug?/:filter?/",
    component: _cd28f08a,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-slug-filter___ru"
  }, {
    path: "/en/catalog/*/",
    component: _299b8b4d,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___en"
  }, {
    path: "/en/profile/*/",
    component: _47c948dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile-all___en"
  }, {
    path: "/ru/catalog/*/",
    component: _299b8b4d,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___ru"
  }, {
    path: "/ru/profile/*/",
    component: _47c948dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile-all___ru"
  }, {
    path: "/en/*/",
    component: _b1deae7a,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/ru/*/",
    component: _b1deae7a,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
